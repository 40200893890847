import * as React from "react"
import { Link, graphql } from "gatsby"
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import {MapPin} from "@styled-icons/remix-fill"

import Layout from "../components/layout"
import Seo from "../components/seo"

import {CodeBlock} from "@styled-icons/boxicons-regular";
import {Computer} from "@styled-icons/material-rounded";
import {SatelliteDish} from "@styled-icons/fa-solid";
import {Buildings} from "@styled-icons/boxicons-regular";

const BlogIndex = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  return (
    <Layout location={location} title={siteTitle}>
      <Seo title="PT. Daya Cipta Cemerlang" />

      <section id="services">
        <div className="section-header">
          <h3>Services</h3>
          <div className="line"/>
        </div>
        <div>
          <div className="flex">
            <div>
              <div>
                <StaticImage
                  className="service-img"
                  layout="fixed"
                  formats={["auto", "webp", "avif"]}
                  src="../images/software.jpg"
                  width={284}
                  height={152}
                  quality={75}
                  alt=""
                  placeholder="blurred"
                />
                <CodeBlock size={80}/>
                <p>Computer software</p>
              </div>
            </div>
            <div>
              <div>
                <StaticImage
                  className="service-img"
                  layout="fixed"
                  formats={["auto", "webp", "avif"]}
                  src="../images/hardware.jpg"
                  width={284}
                  height={152}
                  quality={75}
                  placeholder="blurred"
                  alt=""
                />
                <Computer size={80}/>
                <p>Computer hardware</p>
              </div>
            </div>
            <div>
              <div>
                <StaticImage
                  className="service-img"
                  layout="fixed"
                  formats={["auto", "webp", "avif"]}
                  src="../images/tower.jpg"
                  width={284}
                  height={152}
                  quality={75}
                  placeholder="blurred"
                  alt=""
                />
                &nbsp;<SatelliteDish size={65} style={{marginBottom: 10}}/>
                <p>Telecommunication</p>
              </div>
            </div>
            <div>
              <div>
                <StaticImage
                  className="service-img"
                  layout="fixed"
                  formats={["auto", "webp", "avif"]}
                  src="../images/construction.jpg"
                  width={284}
                  height={152}
                  quality={75}
                  placeholder="blurred"
                  alt=""
                />
                <Buildings size={80}/>
                <p>Construction of educational & office buildings</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section id="projects">
        <div className="section-header">
          <h3>Recent projects</h3>
          <div className="line"/>
        </div>
        <div>
          <ol className="project-list">
            {posts.map(post => {
              const title = post.frontmatter.title || post.fields.slug

              return (
                <li key={post.fields.slug}>
                  <article
                    className="post-list-item"
                    itemScope
                    itemType="http://schema.org/Article"
                  >
                    <header>
                      <div>
                        <GatsbyImage
                          alt={title}
                          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
                          objectFit="cover"
                          objectPosition="50% 50%" />
                      <span><MapPin size={12}/> {post.frontmatter.lokasi}</span>
                      </div>
                      <h4>
                        <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                        </Link>
                      </h4>
                      {/*<small>{post.frontmatter.date}</small>*/}
                    </header>
                    <section>
                      {
                        /*
                        <p
                          dangerouslySetInnerHTML={{
                            __html: post.frontmatter.lokasi || post.excerpt,
                          }}
                          itemProp="description"
                        />
                        */
                      }
                      <p>{post.frontmatter.klien}</p>
                    </section>
                  </article>
                </li>
              )
            })}
          </ol>
        </div>
      </section>
    </Layout>
  )
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          klien
          lokasi
          image {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
`
